[class*="icon-"] {
	@extend .fa;
	font-size: 1rem;
	
	@media screen and (max-width: 800px) {
		font-size: 1rem;
	}
}

.icon-menu {
	@extend .fa-bars;
	font-size: 100%;
}

.icon-menu-close, .icon-close {
	@extend .fa-times;
}

.icon-cart {
	@extend .fa-shopping-cart;
}

.icon-cart-plus {
	@extend .fa-cart-plus;
}

.icon-howto {
	@extend .fa-youtube-play;
}

.icon-shipment {
	@extend .fa-truck;
}

.icon-contact {
	@extend .fa-headphones;
}

.icon-account {
	@extend .fa-user;
}

.icon-faq {
	@extend .fa-question;
}

.icon-about {
	@extend .fa-smile-o;
}

.icon-left {
	@extend .fa-chevron-left;
}

.icon-previous {
	@extend .fa-angle-left;
}

.icon-start {
	@extend .fa-angle-double-left;
}

.icon-next {
	@extend .fa-angle-right;
}

.icon-last {
	@extend .fa-angle-double-right;
}

.icon-right {
	@extend .fa-chevron-right;
}

.icon-down {
	//@extend .fa-chevron-down;
	@extend .fa-caret-down;
}

.icon-up {
	//@extend .fa-chevron-up;
	@extend .fa-caret-up;
}

.icon-left-quote {
	@extend .fa-quote-left;
}

.icon-right-quote {
	@extend .fa-quote-right;
}

.icon-facebook {
	@extend .fa-facebook;
}

.icon-twitter {
	@extend .fa-twitter;
}

.icon-gplus {
	@extend .fa-google-plus;
}

.icon-instagram {
	@extend .fa-instagram;
}

.icon-pinterest {
	@extend .fa-pinterest;
}

.icon-youtube {
	@extend .fa-youtube;
}

.icon-star {
	@extend .fa-star;
}

.icon-mail { 
	@extend .fa-envelope; 
}

.icon-info { 
	@extend .fa-info-circle; 
}

.icon-phone { 
	@extend .fa-phone; 
}

.icon-write { 
	@extend .fa-pencil;
}

.icon-bullet {
	@extend .fa-circle;
}
