//@import "compass/reset";

// Base
@import '../../components/fonts/icons/scss/font-awesome.scss';
// Base
@import 'partials/_base/_w3',
		'partials/_base/_mixins',
		'partials/_base/_config',
		'partials/_base/_icons',
        'partials/_base/_functions',
        'partials/_base/_animate';
        
//fonts
@import '../../components/fonts/raleway/raleway.scss';
@import '../../components/fonts/museo-sans/museo-sans.scss';
@import '../../components/fonts/montserrat/montserrat.scss';
        
* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;

	transition: all ease 0.1s;
	-webkit-transition: all ease 0.1s;
	-moz-transition: all ease 0.1s;
	-o-transition: all ease 0.1s;
}

.paragraph {
	position: relative;
	display: block;
	width: 100%;
	margin: 0.25rem 0;
}

.paragraphs {
	border: 0.1rem solid #aaaaaa;
	border-radius: 0.2rem;
	
	h1, h2, h3, h4, h5, h6 {
		//these are site specific styles
	}
	
	p {
		//these are site specific styles
	}
}

.paragraph-row {
	position: relative;
	display: inline-block;
	width: 100%;
	margin: 1.5rem 0;
	
	&:hover {
		.paragraph-row-controls {
			//display: block;
		}
	}
}

.paragraph-column {
	position: relative;
	display: inline-block;
	//padding: 1%;
	margin: auto 0.75%;
	vertical-align: top;
	z-index: 99;
	
	&:hover {
		.paragraph-column-controls {
			//display: block;
		}
	}
}

.paragraph-100 {
	width: 98%;
	
	@media screen and (max-width: 800px) {
		width: 98%;
	}
}

.paragraph-90 {
	width: 88%;
	
	@media screen and (max-width: 800px) {
		width: 98%;
	}
}

.paragraph-80 {
	width: 78%;
	
	@media screen and (max-width: 800px) {
		width: 98%;
	}
}

.paragraph-70 {
	width: 65%;
	
	@media screen and (max-width: 800px) {
		width: 98%;
	}
}

.paragraph-60 {
	width: 58%;
	
	@media screen and (max-width: 800px) {
		width: 98%;
	}
}

.paragraph-50 {
	width: 47.5%;
	
	@media screen and (max-width: 800px) {
		width: 100%;
	}
}

.paragraph-40 {
	width: 38%;
}

.paragraph-30 {
	width: 31%;
}

.paragraph-20 {
	width: 18%;
}

.paragraph-10 {
	width: 8%;
}

.paragraph-0 {
	display: none;
	width: 0;
	padding: 0;
}

.pull-left { float: left; }

.pull-right { float: right; }

.text-left { text-align: left; }

.text-center { text-align: center; }

.text-right { text-align: right; }

.paragraph-title {
	position: relative;
	display: block;
	font-family: inherit;
	font-weight: 700;
}

.paragraph-subtitle {
	position: relative;
	display: block;
	font-family: inherit;
	font-weight: 500;
}

.paragraph-desc {
	position: relative;
	display: inline-block;
	font-family: inherit;
	font-weight: 300;
}

.paragraph-image {
	position: relative;
	display: block;
	width: 100%;
	vertical-align: top;
	
	img {
		width: 100%;
		height: auto;
	}
}

.paragraph-toolbar {
	border: 0.1rem solid #aaaaaa;
	border-radius: 0.2rem;
	background-color: white;
	
	img {
		display: inline-block;
		margin: 0.25rem;
		vertical-align: top;
	}
	
	input[type="text"] {
		display: inline-block;
		width: 11vw;
		margin: 0.25rem;
		vertical-align: top;
		
		@media screen and (max-width: 757px) {
			width: 100%;
		}
	}
}

.paragraph-row-settings {
	position: absolute;
	top: -1.5rem;
	left: 0;
	width: 1.5rem;
	height: 1.5rem;
	background-color: rgba(255,255,255,0.44);
	color: black;
	line-height: 1.5rem;
	text-align: center;
	cursor: pointer;
}

.paragraph-column-settings {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 1.5rem;
	height: 1.5rem;
	background-color: rgba(255,255,255,0.44);
	color: black;
	line-height: 1.5rem;
	text-align: center;
	cursor: pointer;
}

.paragraph-element-settings {
	position: absolute;
	top: 0;
	right: 0;
	width: 1.5rem;
	height: 1.5rem;
	background-color: rgba(255,255,255,0.44);
	color: black;
	line-height: 1.5rem;
	text-align: center;
	cursor: pointer;
}

.paragraph-row-controls {
	position: absolute;
	display: none;
	top: -1.5rem;
	left: 1.5rem;
	//width: 100%;
	//height: 100%;
	//padding: $padding-small;
	background-color: rgba(255,255,255,0.44);
	color: black;
	z-index: 999;
}

.paragraph-column-controls {
	position: absolute;
	display: none;
	//width: 100%;
	height: 1.5rem;
	left: 1.75rem;
	bottom: 0;
	background-color: rgba(255,255,255,0.44);
	z-index: 9999;
}

.paragraph-element-controls {
	position: absolute;
	display: none;
	height: 1.5rem;
	right: 1.75rem;
	top: 0;
	background-color: rgba(255,255,255,0.44);
	z-index: 99999;
}

.paragraph-row-control, .paragraph-column-control, .paragraph-element-control {
	float: left;
	position: relative;
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	margin: auto 0.25rem;
	background-color: rgba(255,255,255,0.44);
	color: black;
	line-height: 1.5rem;
	text-align: center;
	font-size: 1rem;
	cursor: pointer;
}

.paragraph-toolbar-icon {
	display: inline-block;
	width: 2.2rem;
	height: 2.2rem;
	margin: 0.5rem;
}	

.paragraph-editable {
	border: 0.1rem dotted #666666;
	border-radius: 0.2rem;
}

.icon-remove {
	@extend .fa;
	@extend .fa-trash;
}

