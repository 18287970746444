@font-face {
  font-family: 'montserrat-black';
  src: url('../../components/fonts/montserrat/montserrat-black-webfont.woff') format('woff'),
       url('../../components/fonts/montserrat/montserrat-black-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'montserrat-bold';
  src: url('../../components/fonts/montserrat/montserrat-bold-webfont.woff') format('woff'),
       url('../../components/fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'montserrat';
  src: url('../../components/fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
       url('../../components/fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'montserrat-extralight';
  src: url('../../components/fonts/montserrat/montserrat-extralight-webfont.woff') format('woff');
  src: url('../../components/fonts/montserrat/montserrat-extralight-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'montserrat-medium';
    src: url('../../components/fonts/montserrat/montserrat-medium-webfont.woff') format('woff'),
         url('../../components/fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserrat-thin';
    src: url('../../components/fonts/montserrat/montserrat-thin-webfont.woff') format('woff'),
         url('../../components/fonts/montserrat/montserrat-thin-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}