@charset 'UTF-8';

@function set-text-color($bg-color, $threshold) {
	@if (lightness($bg-color) > $threshold) {
		@return #000;
	}
	@else {
		@return #fff;
	}
}

@function set-bg-color($bg-color) {
	$bg-color: lighten($bg-color, 15%);
	@return $bg-color;
}
