/* Designed by Multiple Designers */
/* FontFace Generated by FontPro */

@font-face {
  font-family: 'Raleway-ExtraLight';
  src: url('../../components/fonts/raleway/Raleway-ExtraLight.eot');
  src: url('../../components/fonts/raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
       url('../../components/fonts/raleway/Raleway-ExtraLight.woff') format('woff'),
       url('../../components/fonts/raleway/Raleway-ExtraLight.ttf') format('truetype'),
       url('../../components/fonts/raleway/Raleway-ExtraLight.svg#Raleway-ExtraLight') format('svg');
}

@font-face {
  font-family: 'Raleway';
  src: url('../../components/fonts/raleway/Raleway-Regular.eot');
  src: url('../../components/fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../components/fonts/raleway/Raleway-Regular.woff') format('woff'),
       url('../../components/fonts/raleway/Raleway-Regular.ttf') format('truetype'),
       url('../../components/fonts/raleway/Raleway-Regular.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('../../components/fonts/raleway/Raleway-Light.eot');
  src: url('../../components/fonts/raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
       url('../../components/fonts/raleway/Raleway-Light.woff') format('woff'),
       url('../../components/fonts/raleway/Raleway-Light.ttf') format('truetype'),
       url('../../components/fonts/raleway/Raleway-Light.svg#Raleway-Light') format('svg');
}

@font-face {
	font-family: 'Raleway-bold';
	src: url('../../components/fonts/raleway/raleway-bold-webfont.woff2') format('woff2'),
         url('../../components/fonts/raleway/raleway-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Raleway-heavy';
	src: url('../../components/fonts/raleway/raleway-heavy-webfont.woff2') format('woff2'),
         url('../../components/fonts/raleway/raleway-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.raleway-light {
  font-family: 'Raleway-Light',sans-serif;
}


.raleway-regular {
  font-family: 'Raleway',sans-serif;
}

.raleway-extralight {
  font-family: 'Raleway-ExtraLight',sans-serif;
}
